import './App.css'
import { useEffect, useState } from 'react'
import Web3 from 'web3'
import { ethers } from 'ethers'
import {
  createWeb3Modal,
  defaultConfig,
  useWeb3Modal,
  useWeb3ModalAccount,
  useWeb3ModalEvents,
  useWeb3ModalState
} from '@web3modal/ethers5/react'
import {
  PROJECT_ID,
  METADATA,
  CHAINS,
  CURRENCY_ICONS,
  NULL_ADDRESS,
  SOVINMARKET_ADDRESS,
  SOVINMARKET_ABI,
  NFT_ABI,
  COLLECTION_OPTIONS
} from './config'
import { createTimestamp, convertTimestamp, shortAddr } from './utils'

createWeb3Modal({
  projectId: PROJECT_ID,
  ethersConfig: defaultConfig({ METADATA }),
  chains: CHAINS,
  defaultChain: CHAINS[0],
  featuredWalletIds: [
    '4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0',
    '19177a98252e07ddfc9af2083ba8e07ef627cb6103467ffebb3f8f4205fd7927',
    '8a0ee50d1f22f6651afcae7eb4253e52a3310b90af5daef78a8c4929a9bb99d4'
  ],
  themeMode: 'dark'
})

let tokensIdArr = []
let auctionsInfoArr = []

const filterOptions = [
  'All auctions',
  'My auctions',
  'Actual auctions'
]
const sortOptions = [
  'By order',
  'By end time'
]

const App = () => {
  const { open } = useWeb3Modal()
  const { address, isConnected } = useWeb3ModalAccount()
  const { selectedNetworkId } = useWeb3ModalState()
  const events = useWeb3ModalEvents()

  const [web3, setWeb3] = useState(null)
  const [loaded, setLoaded] = useState(false)
  const [account, setAccount] = useState(null)
  const [balance, setBalance] = useState('')
  const [sovinmarketBalance, setSovinmarketBalance] = useState('')
  const [currency, setCurrency] = useState(null)
  const [sovinmarket, setSovinmarket] = useState(null)
  const [tokensObj, setTokensObj] = useState(null)
  const [tokensId, setTokensId] = useState(null)
  const [auctionsCount, setAuctionsCount] = useState(null)
  const [auctionsInfo, setAuctionsInfo] = useState(null)
  const [filter, setFilter] = useState(0)
  const [filterOpened, setFilterOpened] = useState(false)
  const [sort, setSort] = useState(0)
  const [sortOpened, setSortOpened] = useState(false)
  const [collectionOpened, setCollectionOpened] = useState(false)
  const [nftOpened, setNftOpened] = useState(false)
  const [imageOpened, setImageOpened] = useState(null)
  const [auctionActive, setAuctionActive] = useState(null)
  const [bidVisible, setBidVisible] = useState(false)
  const [bidLoading, setBidLoading] = useState(null)
  const [bidValue, setBidValue] = useState('')
  const [buyVisible, setBuyVisible] = useState(false)
  const [buyLoading, setBuyLoading] = useState(null)
  const [buyValue, setBuyValue] = useState('')
  const [claimLoading, setClaimLoading] = useState(null)
  const [returnLoading, setReturnLoading] = useState(null)
  const [createVisible, setCreateVisible] = useState(false)
  const [createLoading, setCreateLoading] = useState(false)
  const [createCollectionValue, setCreateCollectionValue] = useState(COLLECTION_OPTIONS[0].value)
  const [createIdValue, setCreateIdValue] = useState('')
  const [createBidValue, setCreateBidValue] = useState('0.0001')
  const [createSellValue, setCreateSellValue] = useState('0')
  const [createEndValue,  setCreateEndValue] = useState('')

  const load = async () => {
    await loadWeb3()
  }

  const loadWeb3 = async () => {
    if (window.ethereum) {
      window.ethereum.on('accountsChanged', handleAccountsChanged)
      setWeb3(new Web3(window.ethereum))
      setLoaded(true)
    }
  }

  const handleAccountsChanged = (accounts) => {
    if (!account || !accounts[0] || account.toLowerCase() !== accounts[0].toLowerCase()) {
      setAccount(accounts[0])
    }
  }

  const clearTokensData = () => {
    tokensIdArr = []
    setTokensId(null)
  }

  const clearAuctionsData = () => {
    auctionsInfoArr = []
    setAuctionsCount(null)
    setAuctionsInfo(null)
  }

  const isExpired = (auction) => {
    const timestamp = Math.round((new Date()).getTime() / 1000)
    return auction.endAuction <= timestamp
  }

  const isSold = (auction) => {
    return auction.sellPrice !== 0 && auction.currentBidPrice >= auction.sellPrice
  }

  const isActual = (auction) => {
    return !isExpired(auction) && !isSold(auction)
  }

  const estimateGas = async (trxParams, onSuccess, onError) => {
    web3.eth.estimateGas(trxParams)
      .then((gasVal) => {
        console.log('Estimate gas value:', gasVal)
        const gasValInt = parseInt(gasVal, 16)
        if (gasValInt > 0) {
          trxParams.gas = parseInt(gasValInt * 1.0).toString()
        }
        if (typeof onSuccess === 'function') {
          onSuccess(trxParams)
        }
      })
      .catch((error) => {
        console.log('Estimate gas failed:', error)
        if (typeof onError === 'function') {
          onError()
        }
      })
  }

  const getBalance = async () => {
    console.log('Get balance...')
    web3.eth.getBalance(account)
      .then((receipt) => {
        console.log('Get balance success:', receipt)
        setBalance((receipt / Math.pow(10, 18)))
      })
      .catch((error) => {
        console.log('Get balance error:', error)
      })
  }

  const createTokensObj = async () => {
    console.log('Create Tokens Object from', createCollectionValue)
    const obj = new web3.eth.Contract(NFT_ABI, createCollectionValue)
    setTokensObj(obj)
  }

  const getTokensCount = async () => {
    console.log('Get tokens count...', )
    clearTokensData()
    tokensObj.methods.balanceOf(account).call()
      .then((count) => {
        if (count) {
          count = Number(count)
          console.log('Get tokens count success:', count)
          if (count > 0) {
            getTokensInfo(count)
          }
        } else {
          console.log('Get tokens count error: no returned count.')
        }
      })
      .catch((error) => {
        console.log('Get tokens count error:', error)
      })
  }

  const getTokensInfo = async (count) => {
    console.log('Get tokens info...')
    for (let ind = 0; ind < count; ind++) {
      getTokenId(ind)
    }
  }

  const getTokenId = async (ind) => {
    console.log('Get token #' + ind + ' ID...')
    tokensObj.methods.tokenOfOwnerByIndex(account, ind).call()
      .then((tokenId) => {
        if (tokenId) {
          console.log('Get token #' + ind + ' ID success:', tokenId)
          const newTokensIdArr = [ ...tokensIdArr, tokenId ]
          tokensIdArr = newTokensIdArr
          setTokensId(tokensIdArr)
        } else {
          console.log('Get token #' + ind + ' ID error: no returned ID.')
        }
      })
      .catch((error) => {
        console.log('Get token #' + ind + ' ID error:', error)
      })
  }

  const getAuctionsCount = async () => {
    console.log('Get all auctions count...')
    clearAuctionsData()
    sovinmarket.methods.index().call()
      .then((count) => {
        if (count) {
          count = Number(count)
          console.log('Get all auctions count success:', count)
          setAuctionsCount(count)
          if (count > 0) {
            getAuctionsInfo(count)
          }
        } else {
          console.log('Get all auctions count error: no returned count.')
        }
      })
      .catch((error) => {
        console.log('Get all auctions count error:', error)
      })
  }

  const getAuctionsInfo = async (count) => {
    console.log('Get auctions info...')
    for (let ind = 0; ind < count; ind++) {
      getAuctionInfo(ind)
    }
  }

  const getAuctionInfo = async (ind) => {
    console.log('Get auction #' + ind + ' info...')
    sovinmarket.methods.getAuctionData(ind).call()
      .then((info) => {
        if (info) {
          console.log('Get auction #' + ind + ' info success:', info)
          createNft(info.nftid, info.addrNFTCollection, (nftName, nftOwner, nftInfo) => {
            const auctionInfo = {
              index: Number(info.index),
              creator: info.creator,
              endAuction: Number(info.endAuction),
              nftid: Number(info.nftid),
              addrNFTCollection: info.addrNFTCollection,
              sellPrice: Number(ethers.utils.formatEther(info.sellPrice)),
              currentBidPrice: Number(ethers.utils.formatEther(info.currentBidPrice)),
              bidCount: Number(info.bidCount),
              currentBidOwner: info.currentBidOwner,
              image: nftInfo.image,
              name: nftName,
              owner: nftOwner
            }
            if (info.status.toString() !== '2') {
              const newAuctionsInfoArr = [ ...auctionsInfoArr, auctionInfo ]
              auctionsInfoArr = newAuctionsInfoArr
              setAuctionsInfo(auctionsInfoArr)              
            }
          })
        } else {
          console.log('Get auction #' + ind + ' info error: no returned info.')
        }
      })
      .catch((error) => {
        console.log('Get auction #' + ind + ' info error: ', error)
      })
  }

  const createNft = (ind, addr, callback) => {
    console.log('Create NFT ' + addr + '.')
    const obj = new web3.eth.Contract(NFT_ABI, addr)
    getNftOwner(obj, ind, callback)
  }

  const getNftOwner = async (obj, ind, callback) => {
    console.log('Get NFT #' + ind + ' owner...')
    obj.methods.ownerOf(ind).call()
      .then((owner) => {
        if (owner) {
          console.log('Get NFT #' + ind + ' owner success:', owner)
          if (owner.toLowerCase() === SOVINMARKET_ADDRESS.toLowerCase()) {
            getTNftName(obj, ind, owner, callback)
          }
        } else {
          console.log('Get NFT #' + ind + ' owner error: no returned owner.')
        }
      })
      .catch((error) => {
        console.log('Get NFT #' + ind + ' owner error:', error)
      })
  }

  const getTNftName = async (obj, ind, owner, callback) => {
    console.log('Get NFT #' + ind + ' name...')
    obj.methods.name().call()
      .then((name) => {
        if (name) {
          console.log('Get NFT #' + ind + ' name success:', name)
          getTNftUri(obj, ind, name, owner, callback)
        } else {
          console.log('Get NFT #' + ind + ' name error: no returned name.')
        }
      })
      .catch((error) => {
        console.log('Get NFT #' + ind + ' name error:', error)
      })
  }

  const getTNftUri = async (obj, ind, name, owner, callback) => {
    console.log('Get NFT #' + ind + ' URI...')
    obj.methods.tokenURI(ind).call()
      .then((uri) => {
        if (uri) {
          console.log('Get NFT #' + ind + ' URI success:', uri)
          getNftInfo(ind, uri, name, owner, callback)
        } else {
          console.log('Get NFT #' + ind + ' URI error: no returned URI.')
        }
      })
      .catch((error) => {
        console.log('Get NFT #' + ind + ' URI error:', error)
      })
  }

  const getNftInfo = async (ind, uri, name, owner, callback) => {
    console.log('Get NFT #' + ind + ' info...')
    fetch(uri, { method: 'get' })
      .then((response) => {
        return response.json()
      })
      .then((info) => {
        if (info) {
          console.log('Get NFT #' + ind + ' info success:', info)
          if (typeof callback === 'function') {
            callback(name, owner, info)
          }
        } else {
          console.log('Get NFT #' + ind + ' info error: no returned info.')
        }
      })
      .catch((error) => {
        console.log('Get NFT #' + ind + ' info error: ', error)
      })
  }

  const sovinmarketCreate = async () => {
    console.log('Create SovinMarket.')
    const obj = new web3.eth.Contract(SOVINMARKET_ABI, SOVINMARKET_ADDRESS)
    setSovinmarket(obj)
  }

  const sovinmarketGetBalance = async () => {
    console.log('Get SovinMarket balance ...')
    web3.eth.getBalance(SOVINMARKET_ADDRESS)
      .then((receipt) => {
        console.log('Get SovinMarket balance success:', receipt)
        setSovinmarketBalance((receipt / Math.pow(10, 18)))
      })
      .catch((error) => {
        console.log('Get SovinMarket balance error:', error)
      })
  }

  const sovinmarketBid = (auction) => {
    const ind = auction.index
    if (Number(bidValue) < auction.currentBidPrice || (auction.bidCount > 0 && Number(bidValue) <= auction.currentBidPrice)) {
      return false
    }
    setBidLoading(true)
    const trxParams = {
      to: sovinmarket._address,
      from: account,
      data: sovinmarket.methods.bid(ind).encodeABI(),
      value: ethers.utils.parseEther(bidValue.toString())
    }
    estimateGas(trxParams, (trxParams) => {
      console.log('Bid ' + bidValue + 'ETH for auction #' + ind + '...')
      web3.eth.sendTransaction(trxParams)
        .on('transactionHash', (trxHash) => {
          console.log('Bid transactionHash:', trxHash)
        })
        .on('receipt', ({ logs }) => {
          if (logs) {
            console.log('Bid ' + bidValue + 'ETH for auction #' + ind + ' success.')
            getBalance()
            sovinmarketGetBalance()
            getAuctionsCount()
            setBidVisible(false)
            setBidValue('')
            setAuctionActive(null)
          } else {
            console.log('Bid ' + bidValue + 'ETH for auction #' + ind + ' error: no returned data.')
          }
          setBidLoading(false)
        })
        .on('error', (error) => {
          console.log('Bid ' + bidValue + 'ETH for auction #' + ind + ' failed:', error)
          setBidLoading(false)
        })
    }, () => {
      setBidLoading(false)
    })
  }

  const sovinmarketBuyAndClaim = (auction) => {
    const ind = auction.index
    if (auction.sellPrice > 0 && Number(buyValue) < auction.sellPrice) {
      return false
    }
    setBuyLoading(true)
    const trxParams = {
      to: sovinmarket._address,
      from: account,
      data: sovinmarket.methods.buyAndClaim(ind).encodeABI(),
      value: ethers.utils.parseEther(buyValue.toString())
    }
    estimateGas(trxParams, (trxParams) => {
      console.log('BuyAndClaim auction #' + ind + ' for ' + buyValue + ' ETH...')
      web3.eth.sendTransaction(trxParams)
        .on('transactionHash', (trxHash) => {
          console.log('BuyAndClaim auction #' + ind + ' for ' + buyValue + ' ETH transactionHash:', trxHash)
        })
        .on('receipt', ({ logs }) => {
          if (logs) {
            console.log('BuyAndClaim auction #' + ind + ' for ' + buyValue + ' ETH success.')
            getBalance()
            sovinmarketGetBalance()
            getAuctionsCount()
            setBuyVisible(false)
            setBuyValue('')
            setAuctionActive(null)
          } else {
            console.log('BuyAndClaim auction #' + ind + ' for ' + buyValue + ' ETH error: no returned data.')
          }
          setBuyLoading(false)
       })
        .on('error', (error) => {
          console.log('BuyAndClaim auction #' + ind + ' for ' + buyValue + ' ETH failed:', error)
          setBuyLoading(false)
        })
    }, () => {
      setBuyLoading(false)
    })
  }

  const sovinmarketClaimNFT = (ind) => {
    setClaimLoading(ind)
    const trxParams = {
      to: sovinmarket._address,
      from: account,
      data: sovinmarket.methods.claimNFT(ind).encodeABI(),
    }
    estimateGas(trxParams, (trxParams) => {
      console.log('Claim NFT for auction #' + ind + '...')
      web3.eth.sendTransaction(trxParams)
        .on('transactionHash', (trxHash) => {
          console.log('Claim NFT for auction #' + ind + ' transactionHash:', trxHash)
        })
        .on('receipt', ({ logs }) => {
          if (logs) {
            console.log('Claim NFT for auction #' + ind + ' success.')
            getBalance()
            sovinmarketGetBalance()
            getAuctionsCount()
          } else {
            console.log('Claim NFT for auction #' + ind + ' error: no returned data.')
          }
          setClaimLoading(null)
        })
        .on('error', (error) => {
          console.log('Claim NFT for auction #' + ind + ' failed:', error)
          setClaimLoading(null)
        })
    }, () => {
      setClaimLoading(null)
    })
  }

  const sovinmarketClaimPayment = (ind) => {
    setClaimLoading(ind)
    const trxParams = {
      to: sovinmarket._address,
      from: account,
      data: sovinmarket.methods.claimPayment(ind).encodeABI(),
    }
    estimateGas(trxParams, (trxParams) => {
      console.log('Claim Payment for auction #' + ind + '...')
      web3.eth.sendTransaction(trxParams)
        .on('transactionHash', (trxHash) => {
          console.log('Claim Payment for auction #' + ind + ' transactionHash:', trxHash)
        })
        .on('receipt', ({ logs }) => {
          if (logs) {
            console.log('Claim Payment for auction #' + ind + ' success.')
            getBalance()
            sovinmarketGetBalance()
            getAuctionsCount()
          } else {
            console.log('Claim Payment for auction #' + ind + ' error: no returned data.')
          }
          setClaimLoading(null)
        })
        .on('error', (error) => {
          console.log('Claim Payment for auction #' + ind + ' failed:', error)
          setClaimLoading(null)
        })
    }, () => {
      setClaimLoading(null)
    })
  }

  const sovinmarketReturnToken = (ind) => {
    setReturnLoading(ind)
    const trxParams = {
      to: sovinmarket._address,
      from: account,
      data: sovinmarket.methods.returnToken(ind).encodeABI(),
    }
    estimateGas(trxParams, (trxParams) => {
      console.log('Return NFT for auction #' + ind + '...')
      web3.eth.sendTransaction(trxParams)
        .on('transactionHash', (trxHash) => {
          console.log('Return NFT for auction #' + ind + ' transactionHash:', trxHash)
        })
        .on('receipt', ({ logs }) => {
          if (logs) {
            console.log('Return NFT for auction #' + ind + ' success.')
            getBalance()
            sovinmarketGetBalance()
            getAuctionsCount()
          } else {
            console.log('Return NFT for auction #' + ind + ' error: no returned data.')
          }
          setReturnLoading(false)
        })
        .on('error', (error) => {
          console.log('Return NFT for auction #' + ind + ' failed:', error)
          setReturnLoading(false)
        })
    }, () => {
      setReturnLoading(false)
    })
  }

  const sovinmarketCreateAuction = () => {
    setCreateLoading(true)
    const params = {
      addrNFTCollection: createCollectionValue,
      _nftid: Number(createIdValue),
      minInitBid: ethers.utils.parseEther(createBidValue.toString()),
      prcBuyOut: ethers.utils.parseEther(createSellValue.toString()),
      endAuction: createTimestamp(createEndValue)
    }
    const trxParams = {
      to: sovinmarket._address,
      from: account,
      data: sovinmarket.methods.createAuction(...Object.values(params)).encodeABI(),
    }
    estimateGas(trxParams, (trxParams) => {
      console.log('Create Auction...')
      web3.eth.sendTransaction(trxParams)
        .on('transactionHash', (trxHash) => {
          console.log('Create Auction transactionHash:', trxHash)
        })
        .on('receipt', ({ logs }) => {
          if (logs) {
            console.log('Create Auction success.')
            //getBalance()
            //sovinmarketGetBalance()
            getAuctionsCount()
            setCreateVisible(false)
            setCreateCollectionValue(COLLECTION_OPTIONS[0].value)
            setCreateIdValue('')
            setCreateBidValue('')
            setCreateSellValue('0')
            setCreateEndValue('')
            setTokensObj(null)
          } else {
            console.log('Create Auction error: no returned data.')
          }
          setCreateLoading(false)
        })
        .on('error', (error) => {
          console.log('Create Auction failed:', error)
          setCreateLoading(false)
        })
    }, () => {
      setCreateLoading(false)
    })
  }

  useEffect(() => {
    if (!loaded) {
      load()
    }
    window.addEventListener('keyup', (e) => {
      if (e.key === 'Escape') {
        setSortOpened(false)
        setFilterOpened(false)
        setImageOpened(null)
        setBidVisible(false)
        setBuyVisible(false)
        setCreateVisible(false)
        setCollectionOpened(false)
        setNftOpened(false)
        setAuctionActive(null)
      }
    })
  })

  useEffect(() => {
    const name = events.data.event
    console.log('Web3modal Event:', name)
  }, [events])

  useEffect(() => {
    if (web3) {
      if (!sovinmarket) {
        sovinmarketCreate()
      }
    }
  }, [web3, sovinmarket])

  useEffect(() => {
    if (!isConnected) {
      setAccount(null)
      setTokensObj(null)
      clearTokensData()
      clearAuctionsData()
    }
  }, [isConnected])

  useEffect(() => {
    console.log('Network set to:', selectedNetworkId)
    if (selectedNetworkId) {
      const chain = CHAINS.find(ch => ch.chainId.toString() === selectedNetworkId.toString())
      if (chain) {
        setCurrency(chain.currency)
      } else {
        setCurrency(null)
      }
    } else {
      setCurrency(null)
    }
  }, [selectedNetworkId])

  useEffect(() => {
    if (!account || !address || account.toLowerCase() !== address.toLowerCase()) {
      setAccount(address)
    }
  }, [address])

  useEffect(() => {
    console.log('Account set to:', account)
    if (account) {
      getBalance()
      sovinmarketGetBalance()
      if (sovinmarket) {
        getAuctionsCount()
      } else {
        clearAuctionsData()
      }
    } else {
      clearAuctionsData()
      clearTokensData()
    }
  }, [account, sovinmarket])

  useEffect(() => {
    console.log('Account set to:', account)
    if (account) {
      if (tokensObj) {
        getTokensCount()
      } else {
        clearTokensData()
      }
    }
  }, [account, tokensObj])

  useEffect(() => {
    console.log('Collection set to:', createCollectionValue)
    if (createCollectionValue !== '') {
      createTokensObj()
    } else {
      setTokensObj(null)
      clearTokensData()
    }
  }, [createCollectionValue])

  const connectParams = CHAINS.length > 1 ? { view: 'Networks' } : {}

  let auctionsInfoList = []
  let nftOptions = [
    {
      label: 'choose nft id',
      value: ''
    }
  ]
  if (auctionsInfo && auctionsCount > 0) {
    auctionsInfoList = auctionsInfo
    if (filter === 1) {
      auctionsInfoList = auctionsInfoList.filter(el => el.creator.toLowerCase() === account.toLowerCase())
    } else if (filter === 2) {
      auctionsInfoList = auctionsInfoList.filter(el => isActual(el))
    }
    if (sort === 1) {
      auctionsInfoList = auctionsInfoList.sort((a, b) => parseInt(a.endAuction) > parseInt(b.endAuction) ? 1 : parseInt(a.endAuction) < parseInt(b.endAuction) ? -1 : 0)
    } else {
      auctionsInfoList = auctionsInfoList.sort((a, b) => parseInt(a.index) < parseInt(b.index) ? 1 : parseInt(a.index) > parseInt(b.index) ? -1 : 0)
    }
  }
  if (tokensId && tokensId.length > 0) {
    tokensId.map(t => {
      if (!nftOptions.find(el => el.value === t)) {
        nftOptions.push({label: t, value: t})
      }
      return true
    })
    nftOptions = nftOptions.sort((a, b) => parseInt(a.value) > parseInt(b.value) ? 1 : parseInt(a.value) < parseInt(b.value) ? -1 : 0)
  }

  return (
    <div className="app" data-theme="dark" onClick={() => { setSortOpened(false); setFilterOpened(false); setCollectionOpened(false); setNftOpened(false); setImageOpened(null); setAuctionActive(null); setBidVisible(false); setBuyVisible(false); setCreateVisible(false) }}>
      <div className="blocks">
        {(isConnected && account) && <div className="block">
          <div className="title center num" data-network={selectedNetworkId}>{shortAddr(account)}</div>
          <div className="balance price num" dangerouslySetInnerHTML={{ __html: balance ? (balance + (currency ? CURRENCY_ICONS.find(el => el.currency === currency)?.icon : '')) : ''}} />
        </div>}
        <div className="block controls">
          {isConnected
            ? <>
                <button onClick={() => open()}>Change Wallet</button>
                {account && <>
                  <div className="controls">
                    <button onClick={(e) => { e.stopPropagation(); setCreateVisible() }}>Create Auction</button>
                  </div>
                </>}
              </>
            : <button onClick={() => open(connectParams)}>Connect Wallet</button>
          }
        </div>
        {(isConnected && account && sovinmarket) && <>
          <hr />
          {((auctionsInfoList && auctionsInfoList.length > 0) || filter !== 0)
            ? <div className="block">
                <div className="auctions-list-head fadeInUp">
                  <div className="auctions-list-title">
                    <span className="title">{filterOptions[filter]}</span>
                    <span className="auctions-count num">/{auctionsInfoList.length}/</span>
                    <span className="auctions-tvl label price" dangerouslySetInnerHTML={{ __html: 'TVL: ' + (sovinmarketBalance ? ('<i class="num">' + sovinmarketBalance + '</i>' + (currency ? CURRENCY_ICONS.find(el => el.currency === currency)?.icon : '')) : '—')}} />
                  </div>
                  <div className="sort-filter">
                    <div className="sort">
                      <div className="select">
                        <div className="selected" onClick={(e) => { e.stopPropagation(); setSortOpened(!sortOpened) }}>
                          <span>{sortOptions[sort]}</span>
                          <svg xmlns="http://www.w3.org/2000/svg" width="512" height="512" x="0" y="0" viewBox="0 0 24 24"><g className="fill" transform="translate(-4,-4)"><path d="M17.293 20.293a1 1 0 0 1 1.414 0L23 24.586l4.293-4.293a1 1 0 0 1 1.414 1.414l-5 5a1 1 0 0 1-1.414 0l-5-5a1 1 0 0 1 0-1.414Z"></path><path d="M23 13a1 1 0 0 1 1 1v12a1 1 0 1 1-2 0V14a1 1 0 0 1 1-1ZM5 16a1 1 0 0 1 1-1h9a1 1 0 1 1 0 2H6a1 1 0 0 1-1-1ZM5 8a1 1 0 0 1 1-1h17a1 1 0 1 1 0 2H6a1 1 0 0 1-1-1ZM5 24a1 1 0 0 1 1-1h7a1 1 0 1 1 0 2H6a1 1 0 0 1-1-1Z"></path></g></svg>
                        </div>
                        {sortOpened && <div className="options fadeInDown" onClick={(e) => e.stopPropagation()}>
                          {sortOptions.map((el, key) => 
                            <div key={key} data-active={sort === key} onClick={() => { setSort(key); setSortOpened(false) }}>{el}</div>
                          )}
                        </div>}
                      </div>
                    </div>
                    <div className="filter">
                      <div className="select">
                        <div className="selected" onClick={(e) => { e.stopPropagation(); setFilterOpened(!filterOpened) }}>
                          <span>{filterOptions[filter]}</span>
                          <svg xmlns="http://www.w3.org/2000/svg" width="512" height="512" viewBox="0 0 512 512"><path d="M510.078,35.509c-3.388-7.304-10.709-11.977-18.761-11.977H20.682c-8.051,0-15.372,4.672-18.761,11.977 s-2.23,15.911,2.969,22.06l183.364,216.828v146.324c0,7.833,4.426,14.995,11.433,18.499l94.127,47.063 c2.919,1.46,6.088,2.183,9.249,2.183c3.782,0,7.552-1.036,10.874-3.089c6.097-3.769,9.809-10.426,9.809-17.594V274.397 L507.11,57.569C512.309,51.42,513.466,42.813,510.078,35.509z M287.27,253.469c-3.157,3.734-4.889,8.466-4.889,13.355V434.32 l-52.763-26.381V266.825c0-4.89-1.733-9.621-4.89-13.355L65.259,64.896h381.482L287.27,253.469z" className="fill"/></svg>
                        </div>
                        {filterOpened && <div className="options fadeInDown" onClick={(e) => e.stopPropagation()}>
                          {filterOptions.map((el, key) => 
                            <div key={key} data-active={filter === key} onClick={() => { setFilter(key); setFilterOpened(false) }}>{el}</div>
                          )}
                        </div>}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="auctions-list">
                  {auctionsInfoList.map((el, key) => {
                    return <div key={key} className="auction fadeInUp" data-index={el.index} data-actual={isActual(el)} data-own={account.toLowerCase() === el.creator.toLowerCase() || account.toLowerCase() === el.currentBidOwner.toLowerCase()} data-disabled={claimLoading === el.index || returnLoading === el.index}>
                      <div className="auction-img">
                        {el.image && <img src={el.image} alt={el.index} onClick={(e) => {e.stopPropagation(); setImageOpened(el.image) }} />}
                      </div>
                      <div className="auction-infos" data-type="token">
                        <div className="auction-info" data-type="name">
                          <span className="label">Collection</span>
                          <span className="value" title={el.addrNFTCollection}>{el.name} #{el.nftid}</span>
                        </div>
                        <div className="auction-info" data-type="creator" data-me={account.toLowerCase() === el.creator.toLowerCase()}>
                          <span className="label">Creator</span>
                          <span className="value">{shortAddr(el.creator)}</span>
                        </div>
                        <div className="auction-info" data-type="end">
                          <span className="label">Ends on</span>
                          <span className="value">{convertTimestamp(el.endAuction)}</span>
                        </div>
                      </div>
                      <div className="auction-title"></div>
                      <div className="auction-info" data-type="bidder" data-me={account.toLowerCase() === el.currentBidOwner.toLowerCase()}>
                        <span><span className="label">Last bidder of</span> <b>{el.bidCount}</b></span>
                        <span className="value">{el.currentBidOwner !== NULL_ADDRESS ? shortAddr(el.currentBidOwner) : '—'}</span>
                      </div>
                      <div className="auction-infos" data-type="bid-buy">
                        <div className="auction-info" data-type="bid">
                          <span className="label">Current bid</span>
                          <span className="value" dangerouslySetInnerHTML={{ __html: '<span class="price">' + el.currentBidPrice + (currency ? CURRENCY_ICONS.find(el => el.currency === currency)?.icon : '') + '</span>'}} />
                          {(account.toLowerCase() !== el.creator.toLowerCase() && isActual(el)) && <button onClick={(e) => { e.stopPropagation(); setAuctionActive(el); setBidValue(el.currentBidPrice); setBidVisible(true) }}>Bid</button>}
                        </div>
                        <div className="auction-info" data-type="buy">
                          <span className="label">Sell price</span>
                          <span className="value" dangerouslySetInnerHTML={{ __html: el.sellPrice ? ('<span class="price">' + el.sellPrice + (currency ? CURRENCY_ICONS.find(el => el.currency === currency)?.icon : '') + '</span>') : '—' }} />
                          {account.toLowerCase() === el.creator.toLowerCase()
                            ? <>
                                {(el.bidCount === 0 && isExpired(el)) && <button onClick={(e) => { e.stopPropagation(); sovinmarketReturnToken(el.index) }}>Return NFT</button>}
                                {(el.bidCount > 0 && !isActual(el)) && <button onClick={(e) => { e.stopPropagation(); sovinmarketClaimPayment(el.index) }} dangerouslySetInnerHTML={{ __html: '<span class="price"> Claim' + (currency ? CURRENCY_ICONS.find(el => el.currency === currency)?.icon : '') + '</span>'}} />}
                              </>
                            : <>
                                {(account.toLowerCase() === el.currentBidOwner.toLowerCase() && !isActual(el)) && <button onClick={(e) => { e.stopPropagation(); sovinmarketClaimNFT(el.index) }}>Claim NFT</button>}
                                {(el.sellPrice > 0 && isActual(el)) && <button onClick={(e) => { e.stopPropagation(); setAuctionActive(el); setBuyValue(el.sellPrice); setBuyVisible() }}>Buy</button>}
                              </>
                          }
                        </div>
                      </div>
                    </div>
                  })}
                </div>
              </div>
            : <div className="block fadeInUp">
                <div className="center">There is no auctions yet</div>
              </div>
          }
          <hr />
          <div className="block note fadeInUp">
            <div className="center">
              <span className="label">You can view your tokens in&nbsp;</span>
              <a href="https://nftacc.sovin.net/">Sovin&nbsp;Pass</a>
            </div>
          </div>
        </>}
      </div>
      <div className="form form-create zoomIn" data-visible={createVisible} onClick={(e) => { e.stopPropagation(); setCollectionOpened(false); setNftOpened(false) }}>
        <div className="title">Create auction</div>
        <div className="field">
          <div className="label">Collection:</div>
          <div className="select" data-opened={collectionOpened} onClick={(e) => { e.stopPropagation(); setNftOpened(false) }}>
            <div className="selected" data-filled={createCollectionValue !== ''} onClick={(e) => { e.stopPropagation(); setCollectionOpened(!collectionOpened) }}>
              <span>{COLLECTION_OPTIONS.find(el => el.value.toLowerCase() === createCollectionValue.toLowerCase()).label}<span>{createCollectionValue !== '' ? (' ' + shortAddr(createCollectionValue)) : ''}</span></span>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><g className="fill"><path d="M20.207 8.147a1 1 0 0 0-1.414 0L12 14.94 5.207 8.147a1 1 0 0 0-1.414 1.414l7.5 7.5a.996.996 0 0 0 1.414.001l7.5-7.5a1 1 0 0 0 0-1.413z"/></g></svg>
            </div>
            {collectionOpened && <div className="options fadeInDown" onClick={(e) => e.stopPropagation()}>
              {COLLECTION_OPTIONS.map((el, key) => 
                <div key={key} data-visible={el.value !== ''} data-active={createCollectionValue === el.value} onClick={() => { setCreateCollectionValue(el.value); setCollectionOpened(false) }}>{el.label}<span>{el.value !== '' ? (' ' + shortAddr(el.value)) : ''}</span></div>
              )}
            </div>}
          </div>
        </div>
        <div className="field">
          <div className="label">NFT ID:</div>
          <div className="select" data-opened={nftOpened} onClick={(e) => { e.stopPropagation(); setCollectionOpened(false) }}>
            <div className="selected" data-filled={createIdValue !== ''} onClick={(e) => { e.stopPropagation(); setNftOpened(!nftOpened) }}>
              <span>{nftOptions.find(el => el.value === createIdValue).label}</span>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><g className="fill"><path d="M20.207 8.147a1 1 0 0 0-1.414 0L12 14.94 5.207 8.147a1 1 0 0 0-1.414 1.414l7.5 7.5a.996.996 0 0 0 1.414.001l7.5-7.5a1 1 0 0 0 0-1.413z"/></g></svg>
            </div>
            {nftOpened && <div className="options fadeInDown" onClick={(e) => e.stopPropagation()}>
              {nftOptions.map((el, key) => 
                <div key={key} data-visible={el.value !== ''} data-active={createIdValue === el.value} onClick={() => { setCreateIdValue(el.value); setNftOpened(false) }}>{el.label}</div>
              )}
            </div>}
          </div>
        </div>
        <div className="field">
          <div className="label">Min bid:</div>
          <input type="text" name="id" value={createBidValue} placeholder="min bid amount" onChange={(e) => setCreateBidValue(e.target.value)} />
        </div>
        <div className="field">
          <div className="label">Sell price:</div>
          <input type="text" name="sell" value={createSellValue} placeholder="sell price" onChange={(e) => setCreateSellValue(e.target.value)} />
        </div>
        <div className="field">
          <div className="label">End time:</div>
          <div className="buttons">
            <button onClick={() => { setCreateEndValue(convertTimestamp(Math.round(new Date().getTime() / 1000 + 300), true)) }}>+5m</button>
            <button onClick={() => { setCreateEndValue(convertTimestamp(Math.round(new Date().getTime() / 1000 + 3600), true)) }}>+1h</button>
            <button onClick={() => { setCreateEndValue(convertTimestamp(Math.round(new Date().getTime() / 1000 + 86400), true)) }}>+1d</button>
          </div>
          <input type="text" name="end" value={createEndValue} placeholder="YYYY.MM.DD HH:MM" onChange={(e) => setCreateEndValue(e.target.value)} />
        </div>
        <div className="field buttons">
          {createLoading
            ? <div className="note flash">creating...</div>
            : <>
                <button type="reset" onClick={() => setCreateVisible(false)}>Cancel</button>
                <button type="submit" onClick={() => sovinmarketCreateAuction()} disabled={createCollectionValue === '' || createIdValue === '' || createBidValue === '' || createEndValue === ''}>Create</button>
              </>
          }
        </div>
      </div>
      <div className="form zoomIn" data-visible={bidVisible} onClick={(e) => e.stopPropagation()}>
        <div className="title" dangerouslySetInnerHTML={{ __html: 'Bid ' + ((account && auctionActive) ? (' for ' + auctionActive.name + ' #' + auctionActive.nftid + '') : '') }} />
        {(account && auctionActive) && <div className="auction">
          <div className="auction-img">
            {auctionActive.image && <img src={auctionActive.image} alt={auctionActive.index} onClick={(e) => {e.stopPropagation(); setImageOpened(auctionActive.image) }} />}
          </div>
          <div className="auction-infos" data-type="token">
            <div className="auction-info" data-type="creator" data-me={account.toLowerCase() === auctionActive.creator.toLowerCase()}>
              <span className="label">Creator</span>
              <span className="value">{shortAddr(auctionActive.creator)}</span>
            </div>
            <div className="auction-info" data-type="end">
              <span className="label">Ends on</span>
              <span className="value">{convertTimestamp(auctionActive.endAuction)}</span>
            </div>
          </div>
        </div>}
        <div className="field">
          <div className="label">Bid amount:</div>
          <input type="text" name="id" value={bidValue} placeholder="bid amount" onChange={(e) => setBidValue(e.target.value)} />
        </div>
        <div className="field buttons">
          {bidLoading
            ? <div className="note flash">bidding...</div>
            : <>
                <button type="reset" onClick={() => setBidVisible(false)}>Cancel</button>
                <button type="submit" onClick={() => sovinmarketBid(auctionActive)} disabled={!bidValue}>Place Bid</button>
              </>
          }
        </div>
      </div>
      <div className="form zoomIn" data-visible={buyVisible} onClick={(e) => e.stopPropagation()}>
        <div className="title" dangerouslySetInnerHTML={{ __html: 'Buy ' + ((account && auctionActive) ? (' ' + auctionActive.name + ' #' + auctionActive.nftid + '') : '') }} />
        {(account && auctionActive) && <div className="auction">
          <div className="auction-img">
            {auctionActive.image && <img src={auctionActive.image} alt={auctionActive.index} onClick={(e) => {e.stopPropagation(); setImageOpened(auctionActive.image) }} />}
          </div>
          <div className="auction-infos" data-type="token">
            <div className="auction-info" data-type="creator" data-me={account.toLowerCase() === auctionActive.creator.toLowerCase()}>
              <span className="label">Creator</span>
              <span className="value">{shortAddr(auctionActive.creator)}</span>
            </div>
            <div className="auction-info" data-type="end">
              <span className="label">Ends on</span>
              <span className="value">{convertTimestamp(auctionActive.endAuction)}</span>
            </div>
          </div>
        </div>}
        <div className="field">
          <div className="label">Price:</div>
          <input type="text" name="id" value={buyValue} placeholder="price" onChange={(e) => setBuyValue(e.target.value)} />
        </div>
        <div className="field buttons">
          {buyLoading
            ? <div className="note flash">buying...</div>
            : <>
                <button type="reset" onClick={() => setBuyVisible(false)}>Cancel</button>
                <button type="submit" onClick={() => sovinmarketBuyAndClaim(auctionActive)} disabled={!buyValue}>Buy</button>
              </>
          }
        </div>
      </div>
      <div className="image zoomIn" data-visible={imageOpened !== null} onClick={(e) => { e.stopPropagation(); setImageOpened(null) }}>
        <div className="image-content">
          <div className="close" onClick={() => setImageOpened(null) } />
          {imageOpened && <img src={imageOpened} alt="" onClick={(e) => e.stopPropagation()} />}
        </div>
      </div>
    </div>
  )
}

export default App;
